.modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid black;
  width: 25%;
  height: 96px; }

.custom-button {
  background-color: lightgray;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer; }

.close:hover,
.close:focus {
  color: white;
  background-color: #005ca6;
  text-decoration: none;
  cursor: pointer; }

.custom-dialog-text {
  text-align: center; }

.custom-dialog-time {
  color: red; }

.custom-Buttons {
  display: flex;
  justify-content: space-around; }

@media screen and (min-width: 500px) and (max-width: 800px) {
  .modal-content {
    width: 45%; } }

@media screen and (min-width: 300px) and (max-width: 550px) {
  .modal-content {
    width: 45%; } }

@media screen and (max-width: 200px) {
  .custom-Buttons {
    display: block; } }
