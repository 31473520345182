.navbar {
  overflow: hidden;
  background-color: #005ca6;
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 1;
  height: 2.5rem;
  display: flex;
  justify-content: space-between; }
  .navbar.uat {
    background-color: darkorange; }
  .navbar.qa {
    background-color: forestgreen; }
  .navbar > div {
    display: flex; }
    .navbar > div:nth-child(1) {
      width: 25%; }
    .navbar > div:nth-child(2) {
      position: fixed;
      width: 50%;
      left: calc(100% - 75%); }
    .navbar > div:nth-child(3) {
      width: 25%;
      justify-content: flex-end; }
      .navbar > div:nth-child(3) > .Help, .navbar > div:nth-child(3) > .Signout {
        margin-right: 10px; }

.navbar a {
  /* float: left; */
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 15px; }

.navbar a:hover {
  background: #ddd;
  color: black; }

.navbar .form-group-select-options {
  height: 1.8rem;
  background-color: rgba(0, 0, 0, 0.2);
  color: white; }

.navbar .form-group-select {
  width: 20vw;
  float: right;
  margin-right: 3rem; }

.headerLogo {
  height: 2.8rem;
  margin-left: 2.4rem;
  cursor: pointer; }

.User {
  color: white;
  font-size: 1rem;
  position: fixed;
  right: 20rem;
  top: 1.1rem; }

.Signout {
  color: white;
  background-color: transparent;
  padding: 0.65rem 1.5rem;
  min-width: 105px;
  /* position: fixed;
  right: 3rem;
  top: 0.4rem; */
  border: 1px solid white;
  border-radius: 5px; }
  .Signout:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer; }

.Help {
  color: white;
  background-color: transparent;
  padding: 0.65rem 1.5rem;
  /* position: fixed;
  right: 10rem;
  top: 0.4rem; */
  border: 1px solid white;
  border-radius: 5px; }
  .Help:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer; }

.appVersion {
  color: white;
  /* position: fixed;
  right: 3rem;
  top: 0.05rem; */
  padding: 10px 20px 10px 10px;
  margin: 0; }

.Counties {
  /* position: fixed; */
  padding: 0 1.5rem;
  /* right: 18rem;
  top: 0.4rem; */
  /* width: 30rem; */
  width: 100%; }
