.GeoNoti-content {
  display: flex;
  justify-content: space-between;
  height: 83vh; }
  .GeoNoti-content .right-container {
    flex: 1;
    display: flex;
    flex-direction: column; }
  .GeoNoti-content .left-container {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    margin-right: 0.7rem;
    position: relative;
    min-width: 27rem; }

.side-search-bar {
  position: relative; }

.page-icon-search {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem; }

.side-searchbox {
  -webkit-appearance: none;
  width: 100%;
  height: 2.2rem;
  margin-left: 0;
  font-size: 1rem;
  background-color: rgba(245, 245, 245, 0.4);
  color: #5a5a5a;
  display: block;
  padding: 0.5rem 0.5rem;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .side-searchbox::placeholder {
    font-weight: lighter;
    color: #ffffff; }
  .side-searchbox:focus {
    background-color: rgba(255, 255, 255, 0.87); }
    .side-searchbox:focus::placeholder {
      font-weight: lighter;
      color: #5e5e5e; }
  .side-searchbox:focus + .page-icon-search {
    display: none; }

.icon-search {
  position: absolute;
  top: 1.3rem;
  right: 3rem; }

.list-container {
  margin-top: 0.5rem;
  position: relative;
  overflow-y: scroll;
  background-color: white;
  height: 100%; }

.list-item {
  width: auto;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0.4rem 1rem 1.8rem; }
  .list-item__left {
    flex: 3;
    text-align: left; }
  .list-item__right {
    flex: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end; }
  .list-item__arrowright {
    flex: 0.3;
    display: flex;
    text-align: right;
    justify-content: flex-end; }
  .list-item__address {
    text-align: left;
    font-weight: 700;
    margin-bottom: 0.7rem; }
  .list-item__name {
    text-align: right; }
  .list-item__time {
    text-align: right;
    margin-bottom: 0.7rem; }
  .list-item__Nametime {
    margin-bottom: 0.7rem;
    font-weight: 700;
    font-size: 0.9rem; }
  .list-item__coursename {
    margin-bottom: 0.7rem;
    font-weight: 700;
    word-wrap: break-word;
    width: 20rem; }
  .list-item__firstsentence {
    font-size: 1rem; }

.selected {
  background-color: #eef6fe; }
  .selected.icon-arrowright {
    opacity: 1; }

.list-item:hover,
.Push-list-item:hover {
  background-color: #eef6fe;
  cursor: pointer; }

.list-item .icon-arrowright,
.Push-list-item .icon-arrowright {
  opacity: 0; }

.selected .list-item__arrowright .icon-arrowright,
.selected .Push-list-item__right .icon-arrowright {
  opacity: 1; }

.list-item:hover .icon-arrowright,
.Push-list-item:hover .icon-arrowright {
  opacity: 1; }

.control-btn-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0; }

.New-Message,
.Create-btn,
.Cancel {
  position: relatve;
  display: flex;
  width: 100%;
  height: 2.2rem;
  font-size: 1rem;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  cursor: pointer; }
  .New-Message:active,
  .Create-btn:active,
  .Cancel:active {
    background-color: #005ca6;
    border: #005ca6;
    color: white;
    transition: all 0.3s; }

.Create-btn {
  background-color: #3a9ef0;
  border-color: #3a9ef0;
  color: white; }

.Cancel {
  background-color: white;
  border-color: white;
  color: #5a5a5a;
  margin-right: 0.5rem; }

.New-Message {
  background-color: #3a9ef0;
  border: none;
  color: white;
  position: relative;
  display: flex;
  justify-content: center; }
  .New-Message:hover {
    box-shadow: 0px 4px 11px 0 rgba(0, 0, 0, 0.2); }

.icon-add {
  margin-right: 10px; }

.title-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 2rem 1rem; }
  .title-container .btn-long {
    flex: 1;
    margin-right: 1rem; }
  .title-container .btn-long-ghost {
    flex: 1;
    margin-right: 0; }
  .title-container .title-content {
    flex: 2;
    margin: 1rem 1rem 1rem 0;
    font-weight: 700; }
  .title-container .title-btn {
    flex: 1;
    display: flex; }

.form-container {
  display: block;
  flex-direction: column;
  position: relative;
  height: 100%;
  background-color: #ffffff;
  margin-top: 0.5rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
  overflow-y: scroll;
  flex-direction: column; }
  .form-container-input {
    margin-left: 2rem;
    margin-right: 0.5rem;
    flex: 1; }
  .form-container-map {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 0.5rem;
    flex: 1; }
  .form-container .geo-page-btn {
    margin-bottom: 1rem; }
  .form-container .map-container {
    margin-right: 2rem; }
  .form-container .google-map {
    height: 30rem;
    margin-bottom: 1rem; }
  .form-container .form-title {
    margin-left: 2rem;
    font-weight: 700;
    margin-bottom: 2rem; }

@media screen and (max-width: 1650px) {
  .GeoNoti-content .list-item .list-item__right,
  .Edu-content .list-item .list-item__right {
    display: none; } }

@media screen and (max-width: 1320px) {
  .form-group {
    display: block; }
  #specialformgroup {
    flex-direction: column;
    margin-bottom: 0; }
    #specialformgroup .form-group-stacked-third {
      width: 100%;
      margin-bottom: 1.2rem;
      margin-right: 0; }
    #specialformgroup .form-group-stacked-third .form-group-text {
      width: calc(100% - 3.5rem); }
    #specialformgroup .form-group-stacked-third .form-group-compond .form-group-select-options {
      width: calc(100% - 1.5rem); } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-datepicker-wrapper input:disabled {
    -ms-transform: translate(0%, -47%); } }

.rename-title {
  flex: 2;
  margin: 1rem 0;
  font-weight: 700; }

.row-break {
  word-wrap: break-word; }
