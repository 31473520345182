.heading {
  height: 10%;
  margin: 1.2rem 0 1.2rem;
  font-weight: lighter;
  color: white; }
  .heading .breadcrumb {
    float: left;
    font-size: 1.2rem;
    padding: 0.7rem 0; }
    .heading .breadcrumb__home {
      position: relative;
      padding: 0.5rem;
      border-radius: 4px;
      color: white;
      cursor: pointer; }
      .heading .breadcrumb__home:hover {
        background-color: #005ca6;
        transition: all 2s; }
    .heading .breadcrumb__home:after {
      content: "";
      width: 0.9rem;
      height: 0.9rem;
      border-color: white;
      border-style: solid;
      border-width: 2px 2px 0 0;
      outline: 1px solid transparent;
      position: absolute;
      right: -15px;
      top: 10px;
      -webkit-transform: rotateZ(45deg);
      -moz-transform: rotateZ(45deg);
      -ms-transform: rotateZ(45deg);
      transform: rotateZ(45deg); }
    .heading .breadcrumb__subpage {
      padding-left: 1.8rem; }
  .heading .Back {
    color: white; }
  .heading .button {
    float: right;
    padding: 0.7rem 2rem;
    font-size: 1rem;
    background-color: transparent;
    border: 1px solid white; }
  .heading:after {
    content: "";
    display: table;
    clear: both; }
