.accordian-header {
  display: flex;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  font-weight: lighter;
  border-bottom: 1px solid #e0e0e0;
  padding: 1.2rem 2rem;
  background-color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.4s;
  border-bottom: 1px solid #e0e0e0; }
  .accordian-header:hover {
    background-color: #ccc; }
  .accordian-header .icon {
    position: relative;
    left: -15px;
    width: 2rem;
    height: 2rem;
    color: #005ca6; }

.accordian-panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out; }
  .accordian-panel.active {
    max-height: 100%; }
