html {
  font-size: 100%; }

body {
  margin: 0;
  padding: 0;
  background: linear-gradient(0deg, #a2b7d8 10%, #135388 100%);
  background: -webkit-linear-gradient(80deg, #a2b7d8 10%, #135388 100%);
  background-size: cover;
  font-family: Lato, Helvetica, Arial, sans-serif;
  color: #5a5a5a;
  min-height: 100vh;
  min-width: 100vw;
  overflow-y: auto;
  scrollbar-track-color: white;
  scrollbar-3dlight-color: #aaa;
  scrollbar-darkshadow-color: #aaa;
  scrollbar-arrow-color: #e6e6e6; }

.version {
  font-size: 10px; }

._loading-overlay {
  height: 100px; }

a:any-link {
  text-decoration: none;
  color: #5a5a5a; }

a {
  text-decoration: none;
  color: #5a5a5a; }

.main {
  margin: 0;
  padding: 3rem 3rem 0 3rem;
  background: transparent;
  position: relative; }

.tile-page {
  max-width: 1500px;
  margin: auto; }

input,
select,
button,
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  appearance: none;
  border-style: solid;
  font-family: Lato, Helvetica, Arial, sans-serif; }

select::-ms-expand {
  display: none; }

::-webkit-scrollbar {
  width: 8px; }

/* Track */
::-webkit-scrollbar-track {
  background: transparent; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.7);
  border-radius: 4px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

/*Buttons*/
.btn-long {
  display: flex;
  width: 50%;
  height: 2.2rem;
  font-size: 1rem;
  margin-right: 1rem;
  justify-content: space-around;
  align-items: center;
  background-color: #3a9ef0;
  border-color: #3a9ef0;
  color: #ffffff;
  border-radius: 4px;
  opacity: 0.9;
  cursor: pointer; }
  .btn-long:hover {
    opacity: 1; }

.btn-long-ghost {
  display: flex;
  height: 2.2rem;
  width: 50%;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  color: #5e5e5e;
  border-radius: 4px;
  cursor: pointer; }

.delete:hover {
  color: #f36161;
  border-color: #f36161; }

.pill {
  border-radius: 16px; }

/* delete modal popup for forms, documents and protocols */
.delete-item-modal-container {
  color: black;
  margin-top: 20rem;
  background-color: white;
  width: 22rem;
  min-height: 5rem;
  padding: 2rem;
  position: absolute;
  z-index: 9999;
  border: none;
  top: 0;
  right: 0;
  transform: translate(-20vw);
  flex-direction: column;
  word-wrap: break-word; }
  .delete-item-modal-container__buttons {
    transform: translate(18%, 0);
    margin-top: 2rem; }
  .delete-item-modal-container__delete {
    color: white;
    background-color: #d94d43;
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;
    border: 2px solid #d94d43;
    border-radius: 4px; }
  .delete-item-modal-container__cancel {
    color: black;
    background-color: white;
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    border-radius: 4px; }

/*Input*/
.form-group-text,
.form-group-select-options,
.form-group-select-style2-options,
.form-group-textarea,
.form-group-compond-text {
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  height: 1.3rem;
  padding: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #f5f5f5;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  resize: vertical; }
  .form-group-text::-moz-placeholder,
  .form-group-select-options::-moz-placeholder,
  .form-group-select-style2-options::-moz-placeholder,
  .form-group-textarea::-moz-placeholder,
  .form-group-compond-text::-moz-placeholder {
    font-weight: lighter;
    font-size: 1rem; }
  .form-group-text:focus, .form-group-text:hover,
  .form-group-select-options:focus,
  .form-group-select-options:hover,
  .form-group-select-style2-options:focus,
  .form-group-select-style2-options:hover,
  .form-group-textarea:focus,
  .form-group-textarea:hover,
  .form-group-compond-text:focus,
  .form-group-compond-text:hover {
    background-color: #f8fbff; }
  .form-group-text.has-error,
  .form-group-select-options.has-error,
  .form-group-select-style2-options.has-error,
  .form-group-textarea.has-error,
  .form-group-compond-text.has-error {
    border: solid 1px #f36161; }
  .form-group-text:disabled,
  .form-group-select-options:disabled,
  .form-group-select-style2-options:disabled,
  .form-group-textarea:disabled,
  .form-group-compond-text:disabled {
    background-color: transparent;
    border-radius: 0;
    border: none;
    padding: 0 1rem 0 0;
    height: auto; }
    .form-group-text:disabled + .icon-expand,
    .form-group-select-options:disabled + .icon-expand,
    .form-group-select-style2-options:disabled + .icon-expand,
    .form-group-textarea:disabled + .icon-expand,
    .form-group-compond-text:disabled + .icon-expand {
      display: none; }
    .form-group-text:disabled:after,
    .form-group-select-options:disabled:after,
    .form-group-select-style2-options:disabled:after,
    .form-group-textarea:disabled:after,
    .form-group-compond-text:disabled:after {
      z-index: -1; }

.form-group-textarea {
  height: 4rem; }

.Edu-content .form-group-textarea {
  height: 10rem; }

.help-block {
  font-size: 0.7rem;
  color: #f36161; }

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  justify-content: space-between; }
  .form-group-stacked, .form-group-stacked-half, .form-group-stacked-textarea {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-right: 1.5rem; }
    .form-group-stacked div > input, .form-group-stacked-half div > input, .form-group-stacked-textarea div > input {
      width: calc(100% - 2rem); }
    .form-group-stacked-label, .form-group-stacked-textarea-label, .form-group-stacked-half-label, .form-group-stacked-half-textarea-label, .form-group-stacked-textarea-label, .form-group-stacked-textarea-textarea-label {
      flex: 0.3 1;
      font-size: 1rem;
      color: #5e5e5e; }
    .form-group-stacked .form-group-text, .form-group-stacked-half .form-group-text, .form-group-stacked-textarea .form-group-text {
      flex: 1 1;
      padding: 0.5rem 1rem; }
      .form-group-stacked .form-group-text:disabled, .form-group-stacked-half .form-group-text:disabled, .form-group-stacked-textarea .form-group-text:disabled {
        background-color: transparent;
        border-radius: 0;
        border: none;
        padding: 0 1rem 0 0;
        height: auto; }
        .form-group-stacked .form-group-text:disabled + .icon-expand, .form-group-stacked-half .form-group-text:disabled + .icon-expand, .form-group-stacked-textarea .form-group-text:disabled + .icon-expand {
          display: none; }
        .form-group-stacked .form-group-text:disabled:after, .form-group-stacked-half .form-group-text:disabled:after, .form-group-stacked-textarea .form-group-text:disabled:after {
          z-index: -1; }
  .form-group-stacked-third {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem; }
    .form-group-stacked-third-label {
      flex: 0.3 1;
      font-size: 1rem;
      color: #5e5e5e; }
    .form-group-stacked-third .form-group-text {
      flex: 1 1;
      padding: 0.5rem 1rem;
      width: calc(100% - 2.5rem); }
      .form-group-stacked-third .form-group-text:disabled {
        background-color: transparent;
        border-radius: 0;
        border: none;
        padding: 0 1rem 0 0;
        height: auto; }
        .form-group-stacked-third .form-group-text:disabled + .icon-expand {
          display: none; }
        .form-group-stacked-third .form-group-text:disabled:after {
          z-index: -1; }
    .form-group-stacked-third .form-group-compond {
      flex: 1 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%; }
      .form-group-stacked-third .form-group-compond .form-group-text {
        flex: 1 1;
        margin-right: 0.5rem;
        width: 30%; }
        .form-group-stacked-third .form-group-compond .form-group-text:disabled {
          background-color: transparent;
          border-radius: 0;
          border: none;
          padding: 0 1rem 0 0;
          height: auto; }
          .form-group-stacked-third .form-group-compond .form-group-text:disabled + .icon-expand {
            display: none; }
          .form-group-stacked-third .form-group-compond .form-group-text:disabled:after {
            z-index: -1; }
        .form-group-stacked-third .form-group-compond .form-group-text:disabled {
          width: 50%; }
      .form-group-stacked-third .form-group-compond .form-group-select {
        position: relative;
        flex: 2 1; }
        .form-group-stacked-third .form-group-compond .form-group-select-options {
          width: calc(100% - 0.5rem);
          padding: 0.5rem 0 0.5rem 0.5rem;
          background: #f5f5f5 url("./assets/images/dropdown-v.png") no-repeat;
          background-size: 20px;
          background-position: 95% 50%;
          border-color: #f0f0f0; }
          .form-group-stacked-third .form-group-compond .form-group-select-options:disabled {
            background-color: transparent;
            border-radius: 0;
            border: none;
            padding: 0 1rem 0 0;
            height: auto; }
            .form-group-stacked-third .form-group-compond .form-group-select-options:disabled + .icon-expand {
              display: none; }
            .form-group-stacked-third .form-group-compond .form-group-select-options:disabled:after {
              z-index: -1; }
  .form-group-horizontal {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .form-group-horizontal-label {
      flex: 0.3 1;
      font-size: 1rem;
      margin-right: 1.2rem;
      font-weight: 600; }
    .form-group-horizontal .form-group-text {
      flex: 1 1;
      padding: 0.5rem 1rem; }

.form-group-select {
  background-position: 80% center;
  position: relative;
  width: 130%; }
  .form-group-select-options {
    flex: 1 1;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1;
    border-radius: 4;
    font-size: 1rem;
    color: #5e5e5e;
    font-weight: lighter;
    background-color: #f5f5f5;
    background: #f5f5f5 url("./assets/images/dropdown-v.png") no-repeat;
    background-size: 20px;
    background-position: 95% 50%;
    cursor: pointer; }
    .form-group-select-options:hover {
      background-color: #f8fbff; }
  .form-group-select-options-page {
    flex: 1 1;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1;
    border: none;
    font-size: 1rem;
    color: #5e5e5e;
    font-weight: lighter;
    cursor: pointer;
    background: #f5f5f5 url("./assets/images/dropdown-v.png") no-repeat;
    background-size: 20px;
    background-position: 95% 50%; }
    .form-group-select-options-page:hover {
      background-color: #f8fbff; }
  .form-group-select .icon-expand {
    position: absolute;
    right: 0.8rem;
    top: 1.9rem; }
  .form-group-select .horizontal-icon-expand {
    position: absolute;
    right: 0.8rem;
    top: 0.9rem; }

.form-group-select-style2 {
  background-position: 80% center;
  display: inline-block;
  position: relative; }
  .form-group-select-style2-options {
    flex: 1 1;
    padding: 0 1.5rem;
    height: 1.8rem;
    border-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid rgba(160, 160, 160, 0.6);
    font-size: 1rem;
    color: #5e5e5e;
    font-weight: lighter;
    margin-top: 0;
    margin-left: 1.5rem;
    background: #f5f5f5 url("./assets/images/dropdown-v.png") no-repeat;
    background-size: 20px;
    background-position: 95% 50%; }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list {
  padding-left: 4px; }

.hide {
  display: none; }

/* pagination */
.app-pagenation {
  display: flex;
  bottom: 0;
  justify-content: space-between;
  height: 3rem;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  box-sizing: border-box;
  color: #333;
  height: 3rem;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding-right: 1rem; }

.pagination {
  height: 36px;
  color: #7195bd;
  flex: 10 1;
  padding-left: 0px;
  justify-content: center; }

.pagination ul {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  margin-left: 0;
  color: #ffffff;
  margin-bottom: 0;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -webkit-padding-start: 0; }

.pagination li {
  display: inline;
  color: #7195bd; }

.pagination a {
  float: left;
  padding: 0 11px;
  line-height: 34px;
  color: #7195bd;
  text-decoration: none;
  border-left-width: 0; }

.pagination a:hover,
.pagination .active a {
  background-color: #7195bd;
  color: #ffffff; }

.pagination a:focus {
  background-color: #7195bd;
  color: #ffffff; }

.pagination .active a {
  color: #ffffff;
  cursor: default; }

.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #999999;
  background-color: transparent;
  cursor: default; }

.pagination li:first-child a {
  border-left-width: 1px;
  border-radius: 3px 0 0 3px; }

.pagination li:last-child a {
  border-radius: 0 3px 3px 0; }

.pagination-centered {
  text-align: center; }

.pagination-right {
  text-align: right; }

.pager {
  margin-left: 0;
  margin-bottom: 18px;
  list-style: none;
  text-align: center;
  color: #7195bd;
  *zoom: 1; }

.pager:before,
.pager:after {
  display: table;
  content: ""; }

.pager:after {
  clear: both; }

.pager li {
  display: inline;
  color: #7195bd; }

.pager a {
  display: inline-block;
  padding: 5px 14px;
  color: #7195bd;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px; }

.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5; }

.pager .next a {
  float: right; }

.pager .previous a {
  float: left; }

.pager .disabled a,
.pager .disabled a:hover {
  color: #999999; }

/* pagination end */
.empty {
  margin: 20% 0 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lato, Helvetica, Arial, sans-serif; }
  .empty__topline {
    flex: 3 1;
    margin-bottom: 20%;
    letter-spacing: 1px; }
    .empty__topline span {
      font-size: 1.5rem; }
  .empty__icon {
    flex: 3 1; }
  .empty__icontitle {
    font-size: 1.2rem;
    margin-top: 6rem;
    font-weight: 700; }
  .empty img {
    height: 4rem; }

.noaccess-empty {
  margin: auto;
  color: white;
  font-size: 1.5rem;
  font-weight: lighter;
  text-align: center;
  margin-top: 8rem;
  animation: topFadeOut 1.3s; }
  .noaccess-empty .empty-404 {
    font-size: 5rem; }
  .noaccess-empty .empty-message {
    margin-bottom: 4rem;
    font-size: 2rem;
    margin-top: 4rem; }
  .noaccess-empty .empty-staytuned {
    font-size: 3rem;
    font-weight: 100;
    margin-bottom: 5rem;
    position: relative; }
  .noaccess-empty .empty-image {
    width: 5rem; }
  .noaccess-empty .form-group .btn-long-white {
    color: #3a9ef0;
    background-color: white;
    border-color: white;
    width: 15rem;
    margin: auto;
    font-size: 1rem;
    height: 3rem;
    font-weight: 700; }

@keyframes topFadeOut {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .form-group-stacked, .form-group-stacked-half {
    height: 5rem; }
    .form-group-stacked div > input, .form-group-stacked-half div > input {
      margin-top: 0rem; }
    .form-group-stacked-label, .form-group-stacked-half-label {
      margin-bottom: 1rem; }
    .form-group-stacked .form-group-text, .form-group-stacked-half .form-group-text {
      margin-top: 1rem; }
    .form-group-stacked-textarea-label, .form-group-stacked-half-textarea-label {
      margin-bottom: 1rem; }
  .form-group-stacked-textarea {
    height: auto; }
  .form-group-stacked-third {
    height: 4.5rem; }
    .form-group-stacked-third-label {
      margin-bottom: 0.5rem; }
    .form-group-stacked-third .form-group-compond .form-group-text {
      margin-top: 0; }
    .form-group-stacked-third .form-group-compond .form-group-select-options {
      margin-top: 0; }
  .form-container-input .form-group .geolocation {
    height: auto; } }

.category-select {
  padding: .5rem 0; }
