.PushNoti-content {
  display: flex;
  justify-content: space-between;
  height: 83vh; }
  .PushNoti-content .right-container {
    flex: 1;
    display: flex;
    flex-direction: column; }
  .PushNoti-content .left-container {
    flex: 0.6;
    min-width: 26rem;
    display: flex;
    flex-direction: column;
    margin-right: 0.6rem;
    position: relative; }

.push-page-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0; }

.push-form-container {
  position: relative;
  height: 95%;
  background-color: #ffffff;
  margin-top: 0.5rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  overflow-y: scroll;
  flex-direction: column; }
  .push-form-container .form-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    font-weight: 700; }

@media screen and (max-width: 2000px) {
  .left-container {
    flex: 0.7;
    min-width: 25rem;
    display: flex;
    flex-direction: column;
    margin-right: 1rem; }
  .right-container {
    flex: 1;
    min-width: 30rem;
    display: flex;
    flex-direction: column;
    margin-right: 1rem; }
  .Push-list-item {
    padding: 1.2rem 2rem; } }

@media screen and (max-width: 1650px) {
  .PushNoti-content .list-item .list-item__right {
    display: none; } }

@media screen and (max-width: 1110px) {
  .left-container {
    flex: 1; }
  .right-container {
    flex: 1; } }
