.cdm-search {
  max-width: 1400px;
  margin: auto; }

.search-bar {
  position: relative;
  margin-bottom: 0.5rem;
  display: flex; }

.searchbox {
  -webkit-appearance: none;
  height: 3.5rem;
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 0rem 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  opacity: 0.9; }
  .searchbox::placeholder {
    font-weight: lighter;
    color: #5e5e5e; }
  .searchbox:hover, .searchbox:focus {
    opacity: 1; }
  .searchbox::-ms-clear {
    display: none; }

.icon-search {
  position: absolute;
  top: 1rem;
  right: 0rem;
  opacity: 1;
  color: #333;
  background-color: transparent;
  padding: 0.3rem 1rem;
  border: 1px solid #333;
  margin-right: 0.5rem;
  border-radius: 5px; }
  .icon-search:hover {
    background-color: #333;
    color: #fff;
    cursor: pointer; }

input[type="search"]:focus + .icon-search {
  opacity: 0; }

.search-count {
  color: white; }

.search-row {
  margin-top: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: lighter;
  padding: 1rem 1.5rem 1rem 1.5rem;
  background-color: #ffffff;
  cursor: pointer; }
  .search-row:hover {
    background-color: #eef6fe;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    transition: transform 0.3s; }

/* .row-employee-name,
.row-employee-title,
.row-employee-ID,
.row-employee-location {
  flex: 1;
} */
.row-employee-name {
  flex-basis: 30%; }

.row-employee-title {
  flex-basis: 25%; }

.row-employee-ID {
  flex-basis: 15%; }

.row-employee-location {
  flex-basis: 10%; }

.row-employee-termination-status {
  flex-basis: 20%;
  text-align: center; }

.row-employee-name {
  font-size: 1.2rem;
  font-weight: 500; }

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: #2196F3; }

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3; }

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); }

.slider.round {
  border-radius: 24px; }

.slider.round:before {
  border-radius: 50%; }

.disabled {
  cursor: default;
  pointer-events: none; }
