.empty {
  margin: 28% 0 30%;
  display: block;
  font-family: Lato, Helvetica, Arial, sans-serif; }
  .empty__topline {
    margin-bottom: 20%;
    letter-spacing: 1px; }
    .empty__topline span {
      font-size: 1.5rem; }
  .empty__icon {
    text-align: center; }
  .empty__icontitle {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
    font-weight: 700; }
  .empty img {
    height: 12rem;
    filter: grayscale(60%);
    filter: gray; }

.empty__topline {
  font-size: 0.9rem;
  line-height: 1.2rem;
  text-align: center; }
