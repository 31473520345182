.cdm-employeedetail {
  max-width: 1400px;
  min-width: 900px;
  margin: auto; }

.modal {
  position: absolute;
  top: 12%;
  left: 30%;
  right: 30%;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-weight: lighter;
  padding: 2rem 0; }
  .modal-container {
    width: 60% !important;
    margin: 0 auto;
    background: #FFFF;
    border-radius: 15px; }
    .modal-container table tbody > tr:first-child > td {
      width: 25%; }
    .modal-container .information-wrapper {
      display: flex;
      justify-content: space-between; }
      .modal-container .information-wrapper > div {
        display: flex;
        flex-direction: column;
        flex-basis: 48%; }
        .modal-container .information-wrapper > div > .title {
          font-weight: bold;
          padding: 0.5rem 0; }
        .modal-container .information-wrapper > div > .content {
          display: flex;
          justify-content: space-between; }
  .modal-heading {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    text-align: center; }
  .modal-employee-name {
    font-size: 1.5rem;
    font-weight: 500;
    display: block;
    margin-bottom: 1.5rem; }
  .modal-employee-job {
    margin-top: 0.5rem; }
  .modal-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2rem auto;
    width: 60%; }
  .modal-form {
    width: 70%;
    margin: auto;
    margin-bottom: 4rem; }

.modal-form-input {
  display: flex;
  flex-direction: column; }

.select-option {
  width: 85%; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(59, 59, 59, 0.6); }

.profile {
  margin-top: 0.5rem;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: lighter;
  background-color: #ffffff;
  border: 1px solid #f5f5f5; }
  .profile * {
    display: flex; }

.profile-heading {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 2rem 1rem 3rem;
  border-bottom: 1px solid #f5f5f5; }
  .profile-heading-left {
    flex-direction: row;
    flex: 3; }
    .profile-heading-left .profile-employee-name {
      font-size: 1.5rem;
      font-weight: 500;
      padding-right: 1rem; }
    .profile-heading-left .profile-employee-job,
    .profile-heading-left .profile-employee-job-status {
      line-height: 2rem;
      padding-right: 1rem; }
    .profile-heading-left .icon-lock {
      margin-top: 0.3rem;
      margin-left: -0.8rem;
      margin-right: 1rem; }
  .profile-heading-right {
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem; }
    .profile-heading-right .profile-edit,
    .profile-heading-right .reset-password {
      color: #3a9ef0;
      cursor: pointer;
      font-weight: 700;
      padding: 0.5rem 2rem;
      margin-right: 0.5rem;
      border: 1px solid #7c8a96; }
      .profile-heading-right .profile-edit:hover,
      .profile-heading-right .reset-password:hover {
        background-color: #eef6fe; }
    .profile-heading-right .reset-password {
      color: #f36161;
      padding: 0.5rem 1rem;
      border: 1px solid #bb5252; }
      .profile-heading-right .reset-password:hover {
        color: #f36161;
        background-color: #fff1f1; }

.profile-tab1 {
  display: inline-block;
  background-color: #447091;
  color: white;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 4.6875rem;
  padding-right: 4.6875rem;
  font-size: 1.3rem;
  margin-bottom: -1.25rem; }

.profile-tab2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  display: inline-block;
  background-color: white;
  color: Black;
  opacity: 1;
  padding-left: 4.6875rem;
  padding-right: 4.6875rem;
  font-size: 1.3rem;
  margin-bottom: -1.25rem; }

.profile-detail {
  flex: 3;
  padding: 1.5rem 2rem 1rem 3rem;
  justify-content: space-between; }
  .profile-detail .profile-detail-column {
    display: block;
    width: 25%;
    flex-direction: column;
    font-size: 0.9rem; }

.detail-type {
  flex: 1;
  flex-direction: row;
  margin-bottom: 1rem; }
  .detail-type .detail-type-name {
    flex: 0.45;
    font-weight: 600; }
  .detail-type .short-name {
    flex: 0.3; }
  .detail-type .email-short-name {
    flex: 0.2; }
  .detail-type .mobile-short-name {
    flex: 0.5; }
  .detail-type .detail-type-value {
    flex: 1;
    color: #5e5e5e; }
  .detail-type .ERT,
  .detail-type .ERT-status,
  .detail-type .ERT-fuction {
    display: inline-block;
    margin-right: 1rem; }

.tooltip-parent {
  position: relatve; }

.row-cert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  font-weight: lighter;
  border-bottom: 1px solid #e0e0e0;
  padding: 1.2rem 2rem;
  background-color: #ffffff;
  cursor: pointer; }
  .row-cert * {
    flex: 1; }
  .row-cert:hover {
    background-color: #eef6fe;
    transition: transform 0.3s; }

.row-cert-doc-name {
  font-size: 1rem;
  margin-bottom: 0.5rem; }

.row-cert-doc-number {
  margin-bottom: 0;
  color: #5e5e5e;
  word-wrap: break-word;
  width: 10rem; }

.row-cert-status {
  display: flex;
  align-items: center; }
  .row-cert-status span {
    flex: 0.3; }
  .row-cert-status svg {
    flex: 0.1;
    height: 20px;
    width: 20px;
    margin-left: 0.5rem; }

.cert-selected {
  background-color: #eef6fe;
  border-left: 8px solid #005ca6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  font-weight: lighter;
  border-bottom: 1px solid #e0e0e0;
  padding: 1.2rem 2rem;
  cursor: pointer; }
  .cert-selected * {
    flex: 1; }
  .cert-selected:hover {
    background-color: #eef6fe;
    transition: transform 0.3s; }

.legend {
  display: flex;
  flex-direction: row;
  padding: 1.5rem 3rem; }
  .legend-column {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center; }
  .legend svg {
    height: 20px;
    width: 25px; }
  .legend-name, .legend-type {
    color: #ffffff;
    display: inline-block;
    flex: 1;
    font-size: 0.8rem; }
  .legend-name {
    transform: translateY(-0.2rem);
    margin-right: 1rem; }

.row-cert-heading {
  color: #ffffff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  padding: 0 2.5rem; }
  .row-cert-heading * {
    flex: 1; }
  .row-cert-heading .add-cert {
    position: absolute;
    right: 0;
    color: white;
    padding: 0.5rem 0.5rem;
    border: 1px solid white;
    cursor: pointer;
    border-radius: 4px; }
    .row-cert-heading .add-cert:hover {
      background-color: rgba(255, 255, 255, 0.2); }

.reset-modal {
  color: black;
  margin-left: 0%;
  margin-top: 20rem;
  background-color: white;
  width: 22rem;
  height: 5rem;
  padding: 2rem;
  position: absolute;
  z-index: 9999;
  border: none;
  top: 0;
  right: calc(50% - 11rem);
  flex-direction: column; }

.row-title-bu-heading {
  display: flex;
  border-bottom: 0.1em solid black;
  color: black;
  font-size: 0.9rem;
  justify-content: space-between;
  align-items: center; }

.index-column {
  justify-content: center;
  font-size: 18px;
  display: flex;
  min-width: 3%; }

.row-title-bu-status {
  justify-content: left;
  font-size: 18px;
  display: flex;
  min-width: 18%; }

.title-bu {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 0.9rem;
  font-weight: lighter;
  border-bottom: 1px solid #e0e0e0;
  background-color: #ffffff; }

.index-content-column {
  justify-content: center;
  display: flex;
  min-width: 3%; }

.row-bus-status {
  color: #534d4d;
  min-width: 18%;
  justify-content: left; }

.flex {
  display: flex;
  flex-direction: column;
  width: 100%; }

@media screen and (max-width: 1250px) {
  .modal {
    left: 20%;
    right: 20%; }
  .profile-detail,
  .profile-heading {
    padding: 1rem 1.2rem; }
  .profile-detail .profile-detail-column {
    width: 40%; }
  .row-cert-status {
    display: flex;
    align-items: center; }
    .row-cert-status span {
      flex: 0.9; }
    .row-cert-status svg {
      flex: 0.2;
      height: 20px;
      width: 20px; }
  .add-cert {
    top: -2.5rem; } }

@media screen and (max-width: 1320px) {
  .modal-form .form-group .form-group-select {
    width: auto; } }

@media all and (min-width: 1250px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .row-cert-heading .add-cert {
    bottom: 0; } }

.cert-row {
  list-style-type: none;
  padding: 0; }
  .cert-row > li {
    padding: 2px 0; }

.booster-overlay {
  background: none !important;
  z-index: 999999 !important; }
  .booster-overlay .custom-ui-alert {
    font-family: Arial, Helvetica, sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    color: #666;
    top: 0;
    position: absolute;
    transform: translateX(-50%); }
    .booster-overlay .custom-ui-alert .react-confirm-alert-button-group {
      justify-content: center; }

.flex {
  display: flex; }

.flex-row {
  display: flex;
  flex-direction: row; }

.flex-column {
  display: flex;
  flex-direction: column; }

.historical-data {
  margin: 0.5rem 0; }
  .historical-data .label {
    display: flex;
    align-items: center;
    flex-shrink: 0; }
  .historical-data .checkbox-group {
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    margin: 0;
    font-size: 0.8rem;
    align-items: center;
    display: flex; }
    .historical-data .checkbox-group:hover {
      background-color: transparent;
      color: inherit; }
    .historical-data .checkbox-group .checkbox-group-checkmark {
      width: 15px;
      height: 15px; }
      .historical-data .checkbox-group .checkbox-group-checkmark:after {
        left: 4px;
        top: 0; }
