.moduleOption {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: relative;
  background: #fcfcfc;
  float: left;
  margin-right: 1.2rem;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  padding: 1.2rem 1.2rem 0 1.2rem;
  border-radius: 5px;
  overflow: hidden; }

.moduleOption h1 {
  color: #fff; }

.moduleIcon {
  margin-top: 2rem;
  height: 15%;
  padding: 5%;
  transition: all 0.2s linear; }

.moduleTitle {
  color: #5a5a5a;
  font-size: 1.2rem;
  margin-top: 1rem;
  transition: all 0.2s linear; }

.moduleDescription {
  height: 38%;
  margin: 1.5rem 2rem;
  font-size: 1rem;
  text-align: center;
  line-height: 2rem;
  color: #838383;
  transition: all 10ms; }

.moduleOptionLink {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #616161;
  background-color: #eeeeee;
  padding: 0.8rem 1rem;
  width: calc(100% - 1.9rem);
  transition: all 0.1s linear; }

.moduleOption:hover {
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer; }
  .moduleOption:hover .moduleDescription,
  .moduleOption:hover .moduleTitle {
    color: #005ca6; }
  .moduleOption:hover .moduleOptionLink {
    color: #ffffff;
    background-color: #005ca6; }

@media screen and (min-width: 400px) {
  .moduleOption {
    width: 75vw;
    height: 75vw; } }

@media screen and (min-width: 768px) {
  .moduleOption {
    width: calc((100vw - 1.2rem - 6rem) / 2);
    height: calc((100vw - 1.2rem - 6rem) * 3.8 / 6); } }

@media screen and (min-width: 1087px) {
  .moduleOption {
    width: calc((100vw - 2 * 1.2rem - 6rem) / 3);
    height: calc((100vw - 2 * 1.2rem - 6rem) * 3.8 / 9); }
  .moduleDescription {
    margin: 1rem 1.5rem; } }

@media screen and (min-width: 1350px) {
  .moduleOption {
    width: calc((100vw - 3 * 1.2rem - 6rem) / 4);
    height: calc((100vw - 3 * 1.2rem - 6rem) * 3.8 / 12); } }

@media screen and (min-width: 1800px) {
  .moduleOption {
    width: calc((100vw - 4 * 1.2rem - 6rem) / 5);
    height: calc((100vw - 4 * 1.2rem - 6rem) * 3.8 / 15); } }
