.Form-content {
  display: flex;
  justify-content: space-between;
  height: 85vh; }
  .Form-content .right-container {
    flex: 1;
    display: flex;
    flex-direction: column; }
  .Form-content .left-container {
    flex: 0.5;
    min-width: 30rem;
    display: flex;
    flex-direction: column;
    margin-right: 0.6rem;
    position: relative; }

.formtype-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background-color: white;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
  overflow-y: scroll;
  flex-direction: column; }
  .formtype-container .form-title {
    margin-top: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    line-height: 2.5rem; }
  .formtype-container .update {
    float: right;
    padding: 0.2rem 2rem;
    margin-right: 4rem;
    width: auto; }
  .formtype-container .assigned-role {
    font-weight: 400; }
  .formtype-container .role-containter {
    display: flex;
    flex-direction: column; }

.checkbox-group {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  width: calc((100% - 4rem) / 3);
  padding: 1rem 0.5rem 1rem 3rem;
  margin-bottom: 12px;
  margin-right: 1.2rem;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */ }
  .checkbox-group:hover {
    background-color: #eef6fe;
    color: black; }
  .checkbox-group-input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .checkbox-group-checkmark {
    position: absolute;
    left: 10px;
    height: 20px;
    width: 20px;
    border: 1px solid #adadad;
    border-radius: 16px;
    background-color: transparent; }
  .checkbox-group:hover .checkbox-group-input ~ .checkbox-group-checkmark {
    background-color: #eaf7f0;
    border-color: #43d993; }
  .checkbox-group:hover .checkbox-group-input ~ .checkbox-group-checkmark:after {
    border-color: #43d993; }
  .checkbox-group-input:checked ~ .checkbox-group-checkmark {
    background-color: #43d993;
    border-color: #43d993; }
  .checkbox-group-checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .checkbox-group-input:checked ~ .checkbox-group-checkmark:after {
    display: block; }
  .checkbox-group-checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.role-select {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 1rem 0.5rem 1rem 4rem;
  margin-bottom: 12px;
  margin-right: 1.2rem;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */ }
  .role-select-input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .role-select-checkmark {
    position: absolute;
    top: 12px;
    left: 1.5rem;
    height: 20px;
    width: 20px;
    border: 1px solid #adadad;
    border-radius: 16px;
    background-color: transparent; }
  .role-select-input:hover ~ .role-select-checkmark {
    background-color: #eaf7f0;
    border-color: #43d993; }
  .role-select-input:checked ~ .role-select-checkmark {
    background-color: #43d993;
    border-color: #43d993; }
  .role-select-checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .role-select-input:checked ~ .role-select-checkmark:after {
    display: block; }
  .role-select-checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.Form-content .checked {
  background-color: #eef6fe;
  margin-bottom: 0; }
  .Form-content .checked .role-select-input:checked ~ .role-select-checkmark {
    background-color: transparent;
    border-color: transparent; }
  .Form-content .checked .role-select-checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #43d993;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.restrict-role-group {
  background-color: #eef6fe;
  padding: 1rem;
  height: 50vh;
  overflow: scroll; }

@media screen and (max-width: 1511px) {
  .checkbox-group {
    width: calc((100% - 4rem) / 2); } }

@media screen and (max-width: 1254px) {
  .checkbox-group {
    width: calc((100% - 4rem)); } }

.downloadable {
  width: 13rem;
  align-items: center;
  justify-content: center; }
