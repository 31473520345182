.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 4rem 3rem 4rem 4rem;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  width: 50vw;
  max-width: 800px;
  margin: 0 auto; }

.login-left,
.login-right {
  flex: 1; }

.login-left {
  padding-top: 0.5rem;
  border-right: 1px solid #e0e0e0;
  padding-right: 3rem; }

.login-right {
  display: flex;
  justify-content: center;
  align-items: center; }

.login-select {
  width: 100%;
  height: 2.1rem; }

.form-group-stacked .login-btn {
  background-color: #005ca6;
  border: #005ca6;
  width: 100%;
  height: 2.5rem;
  margin-right: 1.5rem;
  margin-top: 0.8rem; }
  .form-group-stacked .login-btn:hover, .form-group-stacked .login-btn:active {
    background-color: #3a9ef0;
    border-color: #3a9ef0; }
  .form-group-stacked .login-btn:disabled {
    background-color: #f5f5f5;
    color: #5e5e5e;
    border: #e0e0e0;
    cursor: not-allowed; }

.mainLogo {
  height: 6.5rem;
  transform: translateX(3rem); }

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; }

.login-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #0275d8;
  color: white;
  padding: 1rem 3rem;
  border: 0;
  font-size: medium;
  font-weight: bold;
  max-width: 800px;
  margin: 0 auto;
  cursor: pointer; }

.fade-in-text {
  display: inline-block;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  animation: fadeIn linear 3s;
  -webkit-animation: fadeIn linear 3s;
  -moz-animation: fadeIn linear 3s;
  -o-animation: fadeIn linear 3s;
  -ms-animation: fadeIn linear 3s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media screen and (max-width: 1500px) {
  .login {
    width: 60vw;
    flex-direction: row;
    padding: 2.5rem; }
  .login-left {
    padding: 1rem 2rem 0 0; } }

@media screen and (max-width: 1110px) {
  .login {
    width: 70vw;
    padding: 2rem;
    flex-direction: row; }
  .mainLogo {
    height: 5rem; } }

@media screen and (max-width: 768px) {
  .login {
    flex-direction: column; }
  .login-left {
    border: 0; }
  .mainLogo {
    height: 5rem;
    margin-top: 5rem; }
  .btn-long {
    width: 100vw; }
  .login-right {
    display: flex;
    justify-content: flex-start;
    align-items: center; } }
