.side-panel {
  width: 50rem;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 100%;
  right: auto;
  bottom: auto;
  transform: translate(-100%, 0%);
  transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  font-size: 1rem; }
  .side-panel .fa {
    color: #5e5e5e;
    float: right; }

.icon-close {
  position: fixed;
  right: 1.2rem;
  top: 0.6rem;
  cursor: pointer;
  z-index: 1000; }

.panel-btn-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.4rem;
  margin-top: 2rem;
  height: 3rem;
  padding: 1rem 1.2rem; }

.panel-btn {
  flex: 1;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #3a9ef0;
  cursor: pointer;
  font-size: 1rem;
  background-color: #3a9ef0;
  color: #ffffff; }

.panel-btn-center {
  flex: 1;
  padding: 0.5rem 3rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #3a9ef0;
  cursor: pointer;
  font-size: 1rem;
  background-color: #3a9ef0;
  color: #ffffff;
  margin-top: 13px; }

.panel-btn-center-disabled {
  flex: 1;
  padding: 0.5rem 3rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #3a9ef0;
  cursor: pointer;
  font-size: 1rem;
  background-color: #3a9ef0;
  color: #ffffff;
  margin-top: 13px;
  opacity: 0.3; }

.panel-btn-disabled {
  flex: 1;
  padding: 0.5rem 0;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #3a9ef0;
  cursor: pointer;
  font-size: 1rem;
  background-color: #3a9ef0;
  color: #ffffff;
  opacity: 0.3; }

.panel-lbl {
  padding: 0.5rem 0.9rem; }

.panel-lbl-font {
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin: auto; }

.panel-btn-sm-ghost {
  height: 1.8rem;
  margin-right: 0.4rem;
  justify-content: space-around;
  background-color: #ffffff;
  border-color: #5e5e5e;
  color: #5e5e5e;
  border-radius: 4px;
  cursor: pointer;
  margin-top: -1.5rem; }

.img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px; }

.border-box {
  border-style: solid;
  border-width: 3px;
  padding: 10px;
  margin: 10px;
  border-color: #dadada; }

.cert-info {
  padding: 1rem 1.2rem;
  height: 78vh;
  overflow: auto; }
  .cert-info .thumbnail {
    width: 60%;
    display: block;
    margin: auto; }
  .cert-info .thumbnailPDF {
    width: 85%;
    display: block;
    margin: auto; }
  .cert-info .cert-title-main {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem; }
  .cert-info .cert-title {
    text-align: center;
    margin: 1.5rem 0 1rem; }
  .cert-info .cert-update {
    margin: 0 auto 2rem;
    text-align: center;
    padding: 0.5rem 1rem;
    width: 50%;
    font-size: 1rem;
    font-weight: lighter;
    text-align: center;
    justify-content: space-around;
    background-color: #f5f5f5;
    color: #3a9ef0;
    border-radius: 2rem;
    cursor: pointer; }
  .cert-info .clear-cert {
    font-size: 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    display: block;
    margin: auto;
    height: 2.5rem;
    width: 90%;
    background-color: #fff;
    color: #f36161;
    border: 1px solid #f36161; }
  .cert-info .fileContainer {
    box-shadow: none;
    -webkit-box-shadow: none; }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  position: relative;
  top: 5px;
  right: -3px;
  color: #5a5a5a; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    right: -15px; } }
