.Edu-content {
  display: flex;
  justify-content: space-between;
  height: 83vh; }
  .Edu-content .right-container {
    flex: 1;
    display: flex;
    flex-direction: column; }
  .Edu-content .left-container {
    flex: 0.6;
    display: flex;
    min-width: 26rem;
    flex-direction: column;
    margin-right: 0.7rem;
    position: relative; }

.edu-title-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 2rem 2rem; }
  .edu-title-container .btn-long {
    flex: 1;
    margin-right: 1rem; }
  .edu-title-container .btn-long-ghost {
    flex: 1;
    margin-right: 0; }
  .edu-title-container .edu-title-content {
    flex: 2;
    margin: 1rem 1rem 1rem 0;
    font-weight: 700; }
  .edu-title-container .edu-title-btn {
    flex: 1;
    display: flex; }
