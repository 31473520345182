.Docu-content {
  display: flex;
  justify-content: space-between;
  height: 83vh; }
  .Docu-content .right-container {
    flex: 1;
    display: flex;
    flex-direction: column; }
  .Docu-content .left-container {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    margin-right: 0.7rem;
    position: relative;
    min-width: 25rem; }
  .Docu-content .middle-container {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    margin-right: 0.7rem;
    position: relative;
    min-width: 20rem; }

.state-list-container {
  margin-top: 0.5rem;
  position: relative;
  overflow-y: scroll;
  background-color: white;
  height: 100%; }

.doc-page {
  margin-right: 2rem; }

.doc-reset {
  width: auto;
  margin-right: 2rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  background: #7195bd;
  border: none;
  border-radius: 4px;
  color: white;
  height: 2.5rem;
  padding: 0.5rem 2rem;
  opacity: 0.7;
  cursor: pointer; }
  .doc-reset:hover {
    opacity: 1; }

.doc-pagination-container {
  margin-left: 2rem; }

.rect-pdf__Document {
  margin-left: 2rem; }

.document-access-default {
  margin-bottom: 1rem;
  font-size: 1.2rem; }

.Docu-content .download-setup {
  display: inline-block;
  width: 20%; }
  .Docu-content .download-setup:hover {
    background-color: white; }

.Collapsible__trigger {
  font-size: 1.3em;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding-top: 15px;
  display: block;
  margin-bottom: 20px; }

.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg); }

.Collapsible__trigger:after {
  font-family: 'FontAwesome';
  content: url("https://shakirycharity.org/Pics/downarrow20.png");
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms; }

.rct-collapse-btn {
  border-style: none;
  background-color: transparent;
  cursor: pointer; }

.rct-checkbox:hover {
  cursor: pointer; }

ol li {
  list-style-type: none; }

.react-checkbox-tree input {
  display: none; }

.react-checkbox-tree label {
  padding: 3px;
  align-items: center; }

.react-checkbox-tree label:hover {
  background-color: #eef6fe;
  border-radius: 2px;
  cursor: pointer; }

.rct-title {
  padding-left: 5px;
  padding-right: 10px;
  cursor: pointer; }

.catNode .rct-title :hover {
  border-radius: 2px;
  background-color: #eef6fe; }

.buNode .rct-text :hover {
  cursor: pointer;
  border-radius: 2px;
  background-color: #eef6fe; }

.buSelected > * .rct-title {
  cursor: pointer;
  background-color: #ddefff;
  border-radius: 2px; }
  .catNode .rct-title {
    background-color: white; }

.catSelected .rct-title {
  background-color: #ddefff; }

.rename-category {
  min-width: 100%;
  height: 6rem;
  margin-right: 0; }

.rename-container {
  margin: 0; }
