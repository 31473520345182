input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */ }

@media only screen and (max-width: 1415px) {
  .contactResize {
    width: 10px; } }

@media only screen and (max-width: 1320px) {
  .contactResize {
    width: 100%; } }
